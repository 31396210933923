











































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Driver from '@/models/Driver';
import { driverStoreActions } from '@/store/driver.store';
import Company from '@/models/Company';
import { applicationStoreGetter } from '@/store/application.store';
import { promoterStoreActions, promoterStoreGetters } from '@/store/promoter.store';
import Promoter from '@/models/Promoter';
import moment from 'moment';

const DriverStore = namespace('driver');
const PromoterStore = namespace('promoter');
const ApplicationStore = namespace('application');

@Component({
    mixins: [validationMixin],
    validations: {
        driverCopy: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            phone: {
                required
            },
            driverLicense: {
                required
            }
        }
    }
})
export default class EditDriverComponent extends mixins(ErrorMessageHandlerMixin) {
    @Prop()
    public driver!: Driver | null;

    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @DriverStore.Action(driverStoreActions.CREATE)
    public createDriverAction!: (payload: { driver: Driver, company: Company }) => Promise<Driver>;

    @DriverStore.Action(driverStoreActions.UPDATE)
    public updateDriverAction!: (payload: { driver: Driver }) => Promise<Driver>;

    @PromoterStore.Action(promoterStoreActions.GET)
    private getPromotersAction!: (payload: {from: string, to: string}) => Promise<Promoter>;

    @PromoterStore.Getter(promoterStoreGetters.PROMOTERS)
    private promoters!: Promoter[];

    public driverCopy: Driver = new Driver();
    public validData = false;
    public isLoading: boolean = false;
    private countryCode: string = '+264';

    private onSelect(payload: {dialCode: string, iso2: string, name: string}): void {
        this.countryCode = `+${payload.dialCode}`;
    }

    private async created() {
        const today = moment(new Date()).format('yyyy-MM-DD');
        await this.getPromotersAction({ from: today, to: today });
    }

    private onDocumentsReadyChanged(value: boolean): void {
        if (value) return;
        this.driverCopy.active = false;
    }

    private get promoterOptions() {
        return [...this.promoters];
    }

    /**
     * User status to select from
     */
    private selectStatus: { text: string, value: boolean } [] = [
        { text: this.$t('COMPANY.DIALOG.STATUS_ACTIVE').toString(), value: true },
        { text: this.$t('COMPANY.DIALOG.STATUS_INACTIVE').toString(), value: false }
    ];

    @Watch('driver', { immediate: true })
    public driverChanged() {
        if (this.driver) {
            this.driverCopy = this.driver.copy() as Driver;
            this.validData = !this.$v!.$invalid;
        } else {
            this.driverCopy = Driver.parseFromObject({ active: false });
        }
    }

    public async saveData() {
        const driverToUse = Driver.parseFromObject({
            ...this.driverCopy,
            phone: `${this.countryCode}${this.driverCopy.phone}`
        });

        if (this.validData) {
            try {
                this.isLoading = true;
                if (!this.driver?.id) {
                    await this.createDriverAction({
                        driver: driverToUse,
                        company: this.selectedCompany
                    });
                } else {
                    await this.updateDriverAction({ driver: driverToUse });
                }
                this.dismiss(true);
            } catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false;
            }
        }
    }

    public dismiss(reloadData: boolean = false) {
        if (reloadData) {
            this.driverCopy = new Driver();
        }

        this.$v.$reset();
        this.$emit('closeDialog', reloadData);
    }

    public checkForm(type: string) {
        this.validData = !this.$v!.$invalid;
        this.triggerValidation(type);
    }
}
